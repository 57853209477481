import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { Hero, PartnerServices } from "../../containers"
import PartnerDevelopmentCrumbs from "../../constants/BreadCrumbs/PartnerDevelopmentCrumbs"
import { BreadCrumb } from "../../components"

const PartnerPage = ({ data }) => {
  const image = getImage(
    data.strapiPartner.image.localFile.childImageSharp.gatsbyImageData
  )
  //console.log("PartnerPage - data, text:", data.strapiPartner.text)
  return (
    <section>
      <Hero image={image} title="Partner Development" />
      <BreadCrumb crumbs={PartnerDevelopmentCrumbs} />
      <PartnerServices
        text={data.strapiPartner.text}
        title={data.strapiPartner.title}
      />
    </section>
  )
}

export default PartnerPage

export const query = graphql`
  {
    strapiPartner {
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(formats: AUTO, placeholder: BLURRED)
          }
        }
      }
      title
      text
    }
  }
`
